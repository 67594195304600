<template>
  <div>
    <div class="loginPage">
      <div class="loginWhiteBox">
        <div class="loginLogoBox">
          <img src="@/assets/images/yeet/yeet_logo.png" alt="">
        </div>
        <div class="btnLoaderCtm" v-if="tokenVerification.loader">
          <div class="item loading-6">
            <svg viewBox="25 25 50 50">
              <circle cx="50" cy="50" r="20"></circle>
            </svg>
          </div>
        </div>
        <div v-else-if="!tokenVerification.loader && !tokenVerification.isVerified">
          <div class="loginTitlebox">
            <h4>Reset Link Expired</h4>
            <p>Please generate a new one</p>
            <div class="loginFormBox">
              <div class="col-md-12">
                <div class="forgotPasswordBtn center">
                  <router-link to="/login">Return to Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="loginTitlebox">
            <h4>Reset Password</h4>
            <p>Add your new password</p>
          </div>
          <div class="loginFormBox">
            <ValidationObserver v-slot="{handleSubmit}">
              <form @submit.prevent="handleSubmit(resetPassword)">
                <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                  <div class="formType">
                    <input type="password" v-model="form.password" name="password" class="form-control" placeholder="New password" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="confirm password" rules="required|confirmed:password" v-slot="{ errors }">
                  <div class="formType">
                    <input type="password" v-model="form.confirmPassword" name="password" class="form-control" placeholder="Confirm password" />
                    <span class="text-danger" v-if="errors.length > 0 && errors[0].includes('confirmation')">The confirm password field doesn't match</span>
                    <span class="text-danger" v-else>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div class="row">
                  <div class="col-md-6"></div>
                  <div class="col-md-6">
                    <div class="forgotPasswordBtn">
                      <router-link to="/login">Return to Login</router-link>
                    </div>
                  </div>
                </div> 
                <div class="formSubmitBtn">
                  <loaderBtn v-if="isLoading"/>
                  <button type="submit" :disabled="!tokenVerification.isVerified" v-else>SUBMIT</button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name:'Reset',
  metaInfo() {
    return {
      title: 'Reset Password | YeetCommerce',
    };
  },
  data:()=>({
    form:{
      password:'',
      confirmPassword:'',
    },
    token:'',
    isLoading:false,
    tokenVerification:{
      isVerified:true,
      loader:false,
    }
  }),
  components:{
    loaderBtn
  },
  methods: {
    async resetPassword(){

      let formData = new FormData();

      formData.append('token',this.token);
      formData.append('new_password',this.form.password);
      formData.append('confirm_password',this.form.confirmPassword);

      this.isLoading = true;
      try{
          
        let res = await this.$axios.post('/resetPassword',formData);
        if(res.data.status_code == "1100"){
          
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$router.push({path: '/login'});

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.token){

            this.$message({
              type: 'error',
              showClose: true,
              message: "Reset password link has been expired.",
            });

          }else if(error.response.data.error.includes("expired")){

            this.$message({
              type: 'error',
              showClose: true,
              message: "Reset password link has been expired.",
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });
          
        }

      }finally{

        this.isLoading = false;

      }

    },
  },
  mounted(){

    window.addEventListener('beforeunload', (event) => {
      
      if(this.token){

        event.returnValue = "Are you sure you want to leave? entered information will be lost";

      }

    });

  },
  async beforeMount(){

    if(this.$route.query.token){

      this.token = this.$route.query.token;
      
      let formData = new FormData();

      formData.append('token',this.token);

      this.tokenVerification.loader = true;
      try{
        let res = await this.$axios.post('/forgot-password/reset/verification',formData)
        if(res.data.status_code == "1510"){
          
          this.$router.replace({'query': null});

          this.isVerified = true;

          this.$message({
            type: 'success',
            showClose: true,
            message: res.data.message,
          });

        }
      }catch(error){

        this.tokenVerification.isVerified = false;

        this.$router.replace({'query': null});

        if(error.response){

          if(error.response.data.error.token){

            this.$message({
              type: 'error',
              showClose: true,
              message: "Reset link has been expired. Please generate a new one.",
            });

          }else if(error.response.data.error.includes("expired")){

            this.$message({
              type: 'error',
              showClose: true,
              message: "Reset link has been expired. Please generate a new one.",
            });
            
          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.tokenVerification.loader = false;

      }

    }else{

      this.$router.push({path: '/login'});

    }

  },
}
</script>

<style scoped>
  /* Login New Page Css Start Here */

  .loginPage{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1b3561;
    background: -moz-linear-gradient(-45deg,  #1b3561 0%, #25799d 100%);
    background: -webkit-linear-gradient(-45deg,  #1b3561 0%,#25799d 100%);
    background: linear-gradient(135deg,  #1b3561 0%,#25799d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b3561', endColorstr='#25799d',GradientType=1 );
  }
  .loginWhiteBox{
    max-width: 652px;
    width: 100%;
    background-color: #fff;
    padding: 59px 44px 58px;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 24%);
  }
  .loginLogoBox{
    width: 160px;
    margin: 0 auto;
    margin-bottom: 92px;
  }
  .loginLogoBox a{
    display: block;
  }
  .loginLogoBox img{
    width: 100%;
  }
  .loginTitlebox{
    text-align: center;
    margin-bottom: 33px;
  }
  .loginTitlebox h4{
    font-size: 24px;
    color: #000000;
    margin-bottom: 11px;
    font-weight: 700;
  }
  .loginTitlebox p{
    font-size: 14px;
    color: #9E9B9B;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .loginFormBox .formType label{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
  }
  .loginFormBox .formType label span{
    color: #FD4D5D;
  }
  .loginFormBox .formType input{
    border: 1px solid #e8e8e8 !important;
    background-color: #FCFCFC !important;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 4px;
  }
  .loginFormBox .formType .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  .formType.checkRememberBox{
    display: flex;
    align-items: center;
  }
  .formType.checkRememberBox input{
    border-radius: 0px !important;
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }
  .formType.checkRememberBox label{
    margin-bottom: 0px !important;
    font-size: 16px;
    font-weight: 400;
  }
  .forgotPasswordBtn{
    text-align: right;
  }
  .forgotPasswordBtn.center{
    text-align: center;
    margin-top: 10px;
  }
  .forgotPasswordBtn a{
    font-size: 16px;
    font-weight: 400;
    color: #000;
    display: inline-block;
  }
  .loginFormBox .row{
    margin-bottom: 41px;
  }
  .loginFormBox .formSubmitBtn{
    text-align: center;
    margin-bottom: 20px;
  }
  .loginFormBox .formSubmitBtn button{
    width: 60%;
    margin: 0 auto;
    font-weight: 700;
  }
  .formBottomTxt{
    text-align: center;
  }
  .formBottomTxt p{
    font-size: 14px;
    color: #9E9B9B;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .formBottomTxt p a{
    color: #22678D;
    display: inline-block;
    text-decoration: none;
  }
  .formBottomTxt h4{
    color: #9E9B9B;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
  }
  .formBottomTxt h4 button{
    color: #22678D;
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    border: none;
  }
  .formSubmitBtn button {
    width: 100%;
    background-color: #14223d !important;
    border: none !important;
    color: #fff;
    padding: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .formSubmitBtn button:hover {
    background-color: #fd4e5d !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  /* Login New Page Css End Here */
</style>